@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

body {
  @apply bg-dark py-12 text-white dark:text-black dark:bg-light;
}
h1 {
  @apply text-2xl py-4;
  font-family: "Courier New";
}

h2 {
  @apply text-xl py-4;
  font-family: "Courier New";
}

h3 {
  @apply text-lg py-4;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
}

p {
  @apply py-4;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
}

b {
  @apply text-green-300 dark:text-blue-900;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown p,
.markdown table,
.markdown ul,
.markdown ul li,
.markdown .author
{
  @apply text-gray-300 dark:text-black;
  font-family: 'Nunito', sans-serif;
}

.markdown .callout p,
.markdown .callout .info p,
.markdown .callout .note p{
  color: #002c9b;
}

.markdown table thead tr th {
  @apply text-white dark:text-gray-300;
}
.markdown table tbody tr td {
  @apply text-white dark:text-gray-300;
}